<template>
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-primary">
      <!-- LOGOCHANGE -->
      <img
        :src="require('@/assets/images/logo/SmartTruck.png')"
        style="width:30px"
      >
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https:glogc.com"
        target="_blank"
      >GOBUILDERS NETSOFT SDN BHD (1232479-T)</b-link>
      <span class="d-none d-sm-inline-block ">, All Rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/REFUND_POLICY.docx&embedded=true', 'Refund Policy')" class="ml-25 text-primary" href="">
        Refund Policy
      </b-link>
      |
      <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/DATA_PRIVACY_POLICY.docx&embedded=true', 'Data Privacy Policy')" class="ml-25 text-primary" href="">
        Data Privacy Policy
      </b-link>
      |
      <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/RULES_CONDUCT_POLICY.docx&embedded=true', 'Rules & Conduct Policy')" class="ml-25 text-primary" href="">
        Rules & Conduct Policy
      </b-link>
      |
      <b-link @click="openModal('https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/PRIVACY_TERMS_POLICY.docx&embedded=true', 'Privacy Terms Policy')" class="ml-25 text-primary" href="">
        Privacy Terms Policy
      </b-link>
      |
      <b-link class="ml-25 text-primary" href="#">
        (CONTACT US: 0172881735)
      </b-link>
      <img
        :src="require('@/assets/images/payments/visa.png')"
        style="padding: 0px 10px"
      >
      <img
        :src="require('@/assets/images/payments/mastercard.png')"
      >
    </span>

    <b-modal v-model="showModal" :title="modalTitle" size="lg" hide-footer>
      <iframe
        :src="docUrl"
        width="100%"
        height="750px"
        frameborder="0"
      ></iframe>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BModal,
  },
  data() {
    return {
      showModal: false,
      docUrl: '',
      modalTitle: '', 
    }
  },
  methods: {
    openModal(url, title) {
      this.docUrl = url;
      this.modalTitle = title;
      this.showModal = true;
    }
  }
}
</script>